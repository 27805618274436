import React from "react"

export interface FormStatus {
  error?: Error
  success?: string
}

export function printFormStatus(status?: FormStatus): undefined | React.ReactNode {
  if (!status) return
  if (status.error) return <p className="has-text-danger">{status.error.toString()}</p>
  if (status.success) return <p className="has-text-success">{status.success}</p>
}
