import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import devices from "../../helpers/devices"

const StyledReview = styled.div`
  margin-bottom: 1.5rem;

  .author {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      heigth: 2rem;
      width: 2rem;
      margin-right: 0.5rem;
      border-radius: 50%;
    }

    p.n {
      flex-grow: 1;
    }

    p.d {
      color: var(--grey-1-color);
    }
  }

  .rating {
    display: block;
    margin-bottom: 0.5rem;

    @media ${devices.tablet} {
      display: flex;
      align-items: center;
    }

    p.starability-result {
      margin: 0 0.75rem 0.5rem 0;

      @media ${devices.tablet} {
        margin: 0 0.75rem 0 0;
      }
    }

    p.t {
      flex-grow: 1;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
`

interface ReviewProps {
  review: {
    avatar_url: string
    created_at: string
    full_name: string
    full_review: string
    rating: string
    title: string
  }
}

const Review: React.FunctionComponent<ReviewProps> = ({ review }) => {
  const intl = useIntl()

  const getStarsText = (stars: string) => `Rated: ${stars} stars`

  return (
    <StyledReview>
      <div className="author">
        <img alt="" src={review.avatar_url} />
        <p className="n">{review.full_name || intl.formatMessage({ id: "Anonymous" })}</p>
        <p className="d">{new Date(review.created_at).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "2-digit" })}</p>
      </div>
      <div className="rating">
        <p className="starability-result" data-rating={review.rating}>
          {getStarsText(review.rating)}
        </p>
        <p className="t">{review.title}</p>
      </div>
      <div className="full-review">
        <p>{review.full_review}</p>
      </div>
    </StyledReview>
  )
}

export default Review
