import * as Yup from "yup"
import React, { useState } from "react"
import gravatar from "gravatar"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { useIntl } from "gatsby-plugin-intl"

import { printFormStatus } from "./helpers"

interface ReviewFormProps {
  campaignSlug: string
  className?: string
}

const ReviewForm: React.FunctionComponent<ReviewFormProps> = ({ campaignSlug, className }) => {
  const intl = useIntl()
  const [isSent, setIsSent] = useState(false)

  async function onShareClick() {
    if (typeof navigator.share !== "undefined" && typeof window.location !== "undefined") {
      try {
        await navigator.share({
          title: document ? document.title : "",
          text: document ? document.title : "",
          url: window.location.href,
        })
      } catch (err) {
        console.error(err) // eslint-disable-line no-console
      }
    }
  }

  return (
    <Formik
      initialValues={{
        email: "",
        full_name: "",
        full_review: "",
        privacy_accepted: false,
        title: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(intl.formatMessage({ id: "error_invalid_email" }))
          .required(intl.formatMessage({ id: "error_required" })),
        full_review: Yup.string().required(intl.formatMessage({ id: "error_required" })),
        privacy_accepted: Yup.boolean()
          .required(intl.formatMessage({ id: "error_required" }))
          .oneOf([true], intl.formatMessage({ id: "error_required" })),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        const baseUrl = "https://5jarxtxzse.execute-api.eu-central-1.amazonaws.com/prod/api/v1"
        const payload = {
          ...values,
          avatar_url: `https:${gravatar.url(values.email)}`,
          created_at: new Date().toISOString(),
          full_name: values.full_name.trim(),
          full_review: values.full_review.trim(),
          title: values.title.trim(),
        }
        delete payload.email
        try {
          await fetch(`${baseUrl}/campaigns/${campaignSlug}/leads`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: values.email, payload }),
          })
          setIsSent(true)
          // setStatus({ success: intl.formatMessage({ id: "confirm_submit" }) })
        } catch (err) {
          console.error(err) // eslint-disable-line no-console
          setStatus({ error: intl.formatMessage({ id: "error_submit" }) })
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, status }) =>
        !isSent ? (
          <Form className={className}>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field" style={{ flexGrow: 0 }}>
                  <div className="control name">
                    <fieldset className="starability-basic" style={{ minHeight: 0 }}>
                      <input
                        aria-label={intl.formatMessage({ id: "No rating" })}
                        className="input-no-rate"
                        defaultChecked
                        id="no-rate"
                        name="rating"
                        type="radio"
                        value="0"
                      />
                      <Field type="radio" id="first-rate1" name="rating" value="1" />
                      <label htmlFor="first-rate1" title="Terrible">
                        {`1 ${intl.formatMessage({ id: "star" })}`}
                      </label>
                      <Field type="radio" id="first-rate2" name="rating" value="2" />
                      <label htmlFor="first-rate2" title="Not good">
                        {`2 ${intl.formatMessage({ id: "stars" })}`}
                      </label>
                      <Field type="radio" id="first-rate3" name="rating" value="3" />
                      <label htmlFor="first-rate3" title="Average">
                        {`3 ${intl.formatMessage({ id: "stars" })}`}
                      </label>
                      <Field type="radio" id="first-rate4" name="rating" value="4" />
                      <label htmlFor="first-rate4" title="Very good">
                        {`4 ${intl.formatMessage({ id: "stars" })}`}
                      </label>
                      <Field type="radio" id="first-rate5" name="rating" value="5" />
                      <label htmlFor="first-rate5" title="Amazing">
                        {`5 ${intl.formatMessage({ id: "stars" })}`}
                      </label>
                    </fieldset>
                  </div>
                </div>
                <div className="field">
                  <div className="control title">
                    <Field className="input" type="text" name="title" placeholder={intl.formatMessage({ id: "placeholder_title" })} />
                    <ErrorMessage className="help is-danger" component="p" name="title" />
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control full_review">
                <Field
                  className="textarea"
                  as="textarea"
                  name="full_review"
                  placeholder={intl.formatMessage({ id: "placeholder_full_review" })}
                  rows="2"
                />
                <ErrorMessage className="help is-danger" component="p" name="full_review" />
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <div className="control name">
                    <Field
                      className="input fs-exclude"
                      type="text"
                      name="full_name"
                      placeholder={intl.formatMessage({ id: "placeholder_full_name" })}
                    />
                    <ErrorMessage className="help is-danger" component="p" name="full_name" />
                  </div>
                </div>
                <div className="field">
                  <div className="control email">
                    <Field
                      className="input fs-exclude"
                      type="email"
                      name="email"
                      placeholder={intl.formatMessage({ id: "placeholder_email" })}
                    />
                    <ErrorMessage className="help is-danger" component="p" name="email" />
                  </div>
                </div>
              </div>
            </div>
            <div className="field privacy">
              <label className="checkbox" htmlFor="privacy_accepted">
                <Field id="privacy_accepted" name="privacy_accepted" type="checkbox" />
                &nbsp;
                {intl.formatMessage({ id: "label_privacy_policy_accepted" })}
                &nbsp;
                <a href="/privacy-policy/" rel="noreferrer" target="_blank" title={intl.formatMessage({ id: "Privacy Policy" })}>
                  {intl.formatMessage({ id: "Privacy Policy" })}
                </a>
              </label>
              <ErrorMessage className="help is-danger" component="p" name="privacy_accepted" />
            </div>
            <div className="field submit">
              <div className="control">
                <button className="submit primary small" disabled={isSubmitting} type="submit">
                  {intl.formatMessage({ id: "button_submit" })}
                </button>
              </div>
            </div>
            {printFormStatus(status)}
          </Form>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "16.25rem",
              padding: "0 1.5rem",
              marginBottom: "1.25rem",
              borderRadius: "1rem",
              border: "2px solid var(--primary-color)",
            }}
          >
            <p style={{ fontSize: "1.25rem", textAlign: "center" }}>
              {intl.formatMessage({ id: "review_success_1" })}
              &nbsp;
              <a onClick={onShareClick} title={intl.formatMessage({ id: "review_success_2" })}>
                {intl.formatMessage({ id: "review_success_2" })}
              </a>
            </p>
          </div>
        )
      }
    </Formik>
  )
}

export default ReviewForm
