import React from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Footer from "../Footer"
import Layout from "../Layout"
import Meta from "../Meta"
import Navbar from "../Navbar"
import Review from "../molecules/Review"
import ReviewForm from "../molecules/ReviewForm"
import { H1, H2 } from "../atoms/ResTypography"

import BlogPostStyle from "./BlogPostStyle"
import ReviewsStyle from "./ReviewsStyle"
import reviews from "./reviews.json"
import { TemplateProps } from "./TemplateProps"

const BookPostTemplate: React.FunctionComponent<TemplateProps> = ({ data, location }) => {
  const { excerpt, frontmatter: post, html } = data.markdownRemark
  const intl = useIntl()
  let blogPostAuthor = intl.formatMessage(
    { id: "blog_post_author_template" },
    { author: post.author, date: post.date }
  ) as string
  blogPostAuthor = blogPostAuthor?.replace("{author}", post.author)
  blogPostAuthor = blogPostAuthor?.replace("{date}", post.date)

  function onCtaClick() {
    if (typeof window.gtag_report_conversion_vaccino !== "undefined") {
      window.gtag_report_conversion_vaccino("https://amzn.eu/d/dz0OFZT")
    }
  }

  return (
    <Layout>
      <Meta
        description={excerpt}
        image={{ width: 900, height: 595, src: post.cover }}
        pathname={location.pathname}
        title={post.title}
      />
      <Navbar isFixedTop={false} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10-desktop is-offset-1-desktop is-8-widescreen is-offset-2-widescreen">
              <BlogPostStyle>
                <header className="post-header">
                  <Link to="/blog/">
                    <FormattedMessage id="blog_post_go_back" />
                  </Link>
                  <H1 className="title">{post.title}</H1>
                  <p className="subtitle">{blogPostAuthor}</p>
                </header>
                <section className="post-content" dangerouslySetInnerHTML={{ __html: html }} />
              </BlogPostStyle>
              <ReviewsStyle>
                <H2 className="title">{intl.formatMessage({ id: "Reviews" })}</H2>
                <ReviewForm className="form" campaignSlug="vaccino-startup" />
                {reviews.map((review, index) => (
                  <Review key={index} review={review} />
                ))}
              </ReviewsStyle>
              <div className="has-text-centered">
                <a
                  className="button is-medium is-primary margin-bottom-1rem margin-top-1rem"
                  href="https://amzn.eu/d/dz0OFZT"
                  onClick={onCtaClick}
                  title={intl.formatMessage({ id: "Learn more" })}
                >
                  {intl.formatMessage({ id: "Learn more" })}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer isLandingPage />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($lang: String!, $p: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, path: { eq: $p } }) {
      excerpt
      html
      frontmatter {
        author
        cover
        date(formatString: "DD MMMM YYYY", locale: $lang)
        lang
        path
        title
      }
    }
  }
`

export default BookPostTemplate
